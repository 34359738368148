<template>
    <div>
        <div class="news" v-if="list.length > 0">
            <div class="wrap" :class="{ 'pause': ispause }" @mouseenter="mouseenter" @mouseleave="mouseleave">
                <div class="news_item" v-for="(item, index) in list" :key="index" @click="goYanxiu(item.path)">
                    【新闻】{{ item.title }}
                    <span>查看</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [],
            ispause: false,
            intervalId: null
        }
    },
    methods: {
        mouseenter() {
            this.ispause = true
        },
        mouseleave() {
            this.ispause = false
        },
        // 获取新闻列表
        getNewsList() {
            this.intervalId = setInterval(() => {
                this.$Api.Home.getNewsList()
                    .then(res => {
                        if (res.data) {
                            this.list = res.data
                        } else {
                            this.list = []
                        }
                    })
                    .catch(err => {
                        console.log('获取新闻列表失败:', err)
                    })
            }, 300000)
        },
        // 跳转研修平台
        goYanxiu(url) {
            window.open(url)
        }
    },
    mounted() {
        this.getNewsList();
        this.$Api.Home.getNewsList()
            .then(res => {
                if (res.data) {
                    this.list = res.data
                }
            })
            .catch(err => {
                console.log('获取新闻列表失败:', err)
            })
    },
    destroyed() {
        clearInterval(this.intervalId)
    }
}
</script>

<style lang="less" scoped>
@import url("./news.less");
</style>